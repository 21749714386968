import React, { useState } from "react"
import styled from "styled-components"

const ProfileButton = styled.button`
  outline: none;
  border: none;
  .name {
    font-size: 24px;
    margin: 0;
  }
`

const ProfileBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  overscroll-behavior: contain;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  transform: ${({ modal }) => (modal ? "translateX(0)" : "translateX(-100%)")};
  transition: transform 150ms;
  z-index: 9;
`

const ModalBody = styled.div`
  position: relative;
  background: white;
  @media (max-width: 1024px) {
    overflow: auto;
    height: 500px;
  }
  @media (max-width: 585px) {
    height: 100%;
    width: 100%;
    position: fixed;
    p {
      margin-left: 0px;
      font-size: 10pt;
    }
  }
`

const CloseModal = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 10;

  div {
    width: 20px;
    height: 2px;
    border-radius: 8px;
    transform-origin: 4px;
    background: white;
    position: relative;
    transition: opacity 300ms, transform 300ms;

    :first-child {
      transform: rotate(45deg);
    }
    :nth-child(2) {
      transform: rotate(-45deg);
    }
  }
`

const LeaderProfile = ({ data }) => {
  const [modal, showModal] = useState(false)
  return (
    <div className="modal-container team-modal-container" key={data.id}>
      <div className="person-card-team">
        <ProfileButton
          className="profile-modal-button person-img-container"
          modal={modal}
          onClick={() => showModal(!modal)}
        >
          <h4 className="name">{data.personName}</h4>
          <img
            src={data.personPicture.file.url}
            alt={data.personPicture.file.fileName}
            className="person-img"
          />
          {/* <img
            src={data.personHover.file.url}
            alt={data.personHover.file.fileName}
            className="hover-person-img"
          /> */}
        </ProfileButton>
        {/* <h4>
          <a href={data.link} target="_blank" rel="noreferrer">
            {data.personName}
          </a>
        </h4> */}
        <h5>{data.personTitle}</h5>
        <h5 style={{ width: "75%" }}>{data.personSubtitle}</h5>
        <p>{data.company}</p>
      </div>
      <ProfileBody modal={modal}>
        <ModalBody className="modal-body">
          <div className="profile-modal-header">
            <CloseModal
              className="mobile-menu-button"
              nav={modal}
              onClick={() => showModal(!modal)}
            >
              <div></div>
              <div></div>
            </CloseModal>
            <h3>
              <a href={data.link} target="_blank" rel="noreferrer">
                {data.personName}
              </a>
            </h3>
            <h5>{data.personTitle}</h5>
            <h5>{data.personSubtitle}</h5>
            <p>{data.company}</p>
          </div>
          <div className="profile-modal-body">
            <img
              src={data.personPicture.file.url}
              alt={data.personPicture.file.fileName}
              className="person-img"
            />
            <div className="bio">
              {data.personInfo1 !== null ? (
                <p>{data.personInfo1.personInfo1}</p>
              ) : (
                ""
              )}
              <br />
              {data.personInfo2 !== null ? (
                <p>{data.personInfo2.personInfo2}</p>
              ) : (
                ""
              )}
              <br />
              {data.personInfo3 !== null ? (
                <p>{data.personInfo3.personInfo3}</p>
              ) : (
                ""
              )}
            </div>
          </div>
        </ModalBody>
      </ProfileBody>
    </div>
  )
}

export default LeaderProfile
