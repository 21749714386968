import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LeaderProfile from "../components/LeaderProfile"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Leadership = ({ data: { allContentfulTeam } }) => {
  const leadershipData = allContentfulTeam.edges[0].node
  return (
    <Layout>
      <SEO title="Team" />
      <div className="leadership page-container">
        <div className="container-summary">
          {renderRichText(leadershipData.leadershipHeader, {
            renderNode: {
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="portfolio-header">{children}</h2>
              ),
              [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="section-summary align-center">{children}</p>
              ),
            },
          })}
        </div>
        <div className="corporate-leadership-wrapper">
          <h2>{leadershipData.corporateLeadershipHeader}</h2>
          <div className="corporate-leadership-container">
            {leadershipData.corporateLeadership.references
              .sort((a, b) => (a.order > b.order ? 1 : -1))
              .map(person => (
                <LeaderProfile key={person.id} data={person} />
              ))}
          </div>
        </div>
        <div className="business-leadership-wrapper">
          <h2>{leadershipData.businessUnitHeader}</h2>
          <div className="business-leadership-container">
            {leadershipData.businessLeadership.references.map(person => (
              <LeaderProfile key={person.id} data={person} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Leadership

export const pageQuery = graphql`
  {
    allContentfulTeam {
      edges {
        node {
          slug
          title
          corporateLeadership {
            references {
              personName
              personPicture {
                file {
                  fileName
                  url
                }
              }
              personTitle
              id
              personHover {
                file {
                  fileName
                  url
                }
              }
              link
              personInfo1 {
                personInfo1
              }
              personInfo2 {
                personInfo2
              }
              personInfo3 {
                personInfo3
              }
              personSubtitle
              order
            }
            raw
          }
          businessLeadership {
            raw
            references {
              personPicture {
                file {
                  fileName
                  url
                }
              }
              personTitle
              personName
              id
              personHover {
                file {
                  url
                  fileName
                }
              }
              personInfo1 {
                personInfo1
              }
              personInfo2 {
                personInfo2
              }
              personInfo3 {
                personInfo3
              }
              link
              company
              personSubtitle
            }
          }
          leadershipHeader {
            raw
          }
          businessUnitHeader
          corporateLeadershipHeader
        }
      }
    }
  }
`
